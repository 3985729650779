<style>
  .c-nav-content {
    display: grid;
    grid-column-gap: 25px;
    grid-template-columns: auto minmax(1px,1440px) auto;
    grid-template-areas: ". page-content .";
  }
  .c-nav-container {
    grid-area: page-content;
  }
  .c-page-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "left right";
    margin-bottom: 25px;
    overflow-x: auto !important;
  }
  .c-page-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: left;
    justify-content: center;
  }
  .c-page-header-right {
    grid-area: right;
    align-items: center;
  }
  .c-nav-content nav {
    display: block;
  }
  .c-nav-content ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .c-nav-content a {
    text-decoration: none;
    cursor: pointer;
  }
  .c-nav-horizontal .c-nav-list {
    display: grid;
    justify-content: start;
    align-items: center;
    grid-auto-flow: column;
    grid-column-gap: 15px;
  }
  .c-nav-horizontal .c-nav-item {
    display: grid;
    align-items: center;
    margin: 0;
    height: 3rem;
    line-height: 3rem;
    grid-column-gap: 10px;
    grid-auto-flow: column;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  .c-nav-item {
    display: flex;
    margin: 0 0 13px;
    font-size: 0.85rem;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .c-nav-item-active .c-nav-link, .c-nav-item-active .c-nav-link:hover, .c-nav-item.active .c-nav-link, .c-nav-item.active .c-nav-link:hover {
    color: var(--primary);
  }
  .c-nav-link {
    transition: color .15s;
    position: relative;
    color: var(--secondary);
    letter-spacing: .05rem;
  }
  .c-nav-horizontal .c-nav-item-active a:after, .c-nav-horizontal .c-nav-item.active a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--primary);
  }
  .c-nav button {
    padding: 0;
    border: 0;
    background: none;
  }
  .c-button {
    display: grid;
    justify-content: stretch;
  }
  .c-button-e {
    transition: background-color .15s;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Montserrat,Roboto,sans-serif;
    font-weight: 500;
    white-space: nowrap;
    color: #fff;
    background-color: var(--primary);
  }
  .c-button-e, .c-button-content {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-column-gap: 8px;
    grid-auto-flow: column;
  }
</style>

<script>
import appConfig from "@/app.config";

import {mapState} from "vuex";

const componentMap = {
  'overview': 'BillingOverview',
  'payment-methods': 'PaymentMethods',
  'subscription': 'Subscription',
  'invoices': 'Invoices'
};

export default {
  components: {
    BillingOverview: () => import('@/components/lazy/billing/Overview'),
    PaymentMethods: () => import('@/components/lazy/billing/PaymentMethods'),
    Subscription: () => import('@/components/lazy/billing/Subscription'),
    Invoices: () => import('@/components/lazy/billing/Invoices')
  },
  validations: {

  },
  methods: {
    viewToUrlPath(targetView) {
      let targetUrlPath = null;
      for (const [urlPath, view] of Object.entries(componentMap)) {
        if(targetView === view) {
          targetUrlPath = urlPath;
          break;
        }
      }
      return targetUrlPath;
    },
    urlPathToView(urlPath) {
      return componentMap[urlPath];
    },
    async navigate(targetView, allowReload) {
      if(targetView !== this.view) {
        history.pushState({}, null, `/billing/${this.viewToUrlPath(targetView)}`);
        this.view = targetView;
        this.ready = true;
      } else if(allowReload === true) {
        this.ready = false;
        this.view = null;
        this.$nextTick(() => {
          this.view = targetView;
          this.ready = true;
        })
      } else {
        this.view = targetView;
        this.ready = true;
      }
    },
    navIsActive(view, childView) {
      return (this.view === view || this.view === childView);
    },
    async onChildNavigationRequest(request) {
      await this.navigate(request.component);
    }
  },
  created() {
    if(this.$route.params.component)
      this.navigate(this.urlPathToView(this.$route.params.component));
    else
      this.navigate(this.defaultView);
  },
  data() {
    return {
      ready: false,
      view: null,
      defaultView: 'Subscription'
    }
  }
};
</script>

<template>
  <div class="c-nav-content">
    <div class="c-nav-container">
      <div class="c-page-header">
        <div class="c-page-header-left">
          <div class="c-nav-horizontal">
            <nav class="c-nav">
              <ul class="c-nav-list">
                <li class="c-nav-item" :class="{'c-nav-item-active': navIsActive('Subscription'), 'active': navIsActive('Subscription', 'Checkout')}">
                  <a class="c-nav-link" v-on:click="navigate('Subscription', true)">
                    {{$t('billing.subscription.title')}}
                  </a>
                </li>

                <li class="c-nav-item" :class="{'c-nav-item-active': navIsActive('BillingOverview'), 'active': navIsActive('BillingOverview')}">
                  <a class="c-nav-link" v-on:click="navigate('BillingOverview')">
                    {{$t('billing.overview.title')}}
                  </a>
                </li>

                <li class="c-nav-item" :class="{'c-nav-item-active': navIsActive('PaymentMethods'), 'active': navIsActive('PaymentMethods')}">
                  <a class="c-nav-link" v-on:click="navigate('PaymentMethods')">
                    {{$t('billing.payment.title')}}
                  </a>
                </li>

                <li class="c-nav-item" :class="{'c-nav-item-active': navIsActive('Invoices'), 'active': navIsActive('Invoices')}">
                  <a class="c-nav-link" v-on:click="navigate('Invoices')">
                    {{$t('billing.invoices.title')}}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!-- ---- -->
      <div class="c-body">
        <component v-if="ready" v-bind:is="view" @childNavigate="onChildNavigationRequest"></component>
      </div>
    </div>
  </div>
</template>